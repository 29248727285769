import React from "react";
import {useEffect, useState} from "react";
import Chart from "chart.js";
import {FadeLoader} from "react-spinners";
import app from "../../services/firebase.config"
import axios from "axios";
import numbro from "numbro";

// model for current_stockout, just to aid development
const mockCurrentStockout = {
  percentage: 0.80, // fraction of skus
  stock_status: "Out of stock",
}

export default function CardCurrentStockoutPieChart({region, brand}) {
  const [currentStockout, setCurrentStockout] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(!region) return;

    const api = `${process.env.REACT_APP_API_URL}/current_stockout`;
    
    const config ={
      headers: {
        "firebase-authorization-token": window.localStorage.getItem("firebase-authorization-token"),
      },
      params: {
        vendor_email: app.auth().currentUser.email,
        region_id: region,
        brand_id: brand,
      }
    }
    setLoading(true);
    axios.get(api, config)
        .then((response) => {
            setCurrentStockout(response.data.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }, [region, brand]);

  useEffect(() => {
    if(!currentStockout) return;

    const config = {
      type: "pie",
      data: {
        labels: currentStockout.map(data => data.stock_status),
        datasets: [
          {
            label: "Percentage",
            backgroundColor: ["#EC6B56", "#4caf50"],
            //borderColor: "#ee2400",
            data: currentStockout.map(data => (data.percentage * 100).toFixed(2)),
            fill: false,
          }
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return data.labels[tooltipItem.index] + ": " + data.datasets[0].data[tooltipItem.index] + "%";
            }
          }
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
      },
    };
    let ctx = document.getElementById("pie-chart").getContext("2d");
    window.myCurrentStockoutPieChart?.destroy();
    window.myCurrentStockoutPieChart = new Chart(ctx, config);
  }, [currentStockout]);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h2 className="text-blueGray-700 text-xl font-semibold">
                Current Snapshot: &nbsp;
                {(currentStockout && !loading) && (
                  <span className="text-red-500">
                    {(currentStockout[0].percentage * 100).toFixed(2)}% Out of Stock
                  </span>
              )}
              </h2>
            </div>
            <FadeLoader
                  color="#4caf50"
                  loading={loading}
                  height={10}
                  margin={-5}
                  width={3}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          {currentStockout && (
              <div className="relative h-350-px flex items-center justify-center">
                <div className="h-300-px align-middle">
                  <canvas id="pie-chart"></canvas>
                </div>
              </div>
          )}
        </div>
      </div>
    </>
  );
}
