import React from "react";
import {useState, useEffect} from "react";
import axios from 'axios';
import {PropagateLoader} from "react-spinners";

// components

import Header from "components/Headers/Header.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";

// model for response data
const mockData = {
    vendor_skus: [],
    hourly_stockout: [],
    current_stockout: [],
    monthly_lost_sales: [],
}


export default function Admin() {
  const [region, setRegion] = useState(null);
  const [brand, setBrand] = useState(null);

  const selectRegion = (r) => {
      setRegion(r);
  };

  const selectBrand = (b) => {
        setBrand(b);
  };

  return (
    <>
        {/* Header */}
        <Header region={region} selectRegion={selectRegion} selectBrand={selectBrand}/>
        <div className="px-4 md:px-10 mx-auto w-full -m-24" hidden={region !== ""}>
            <div className="relative bg-white rounded-lg shadow-lg">
                <div className="px-4 py-6 sm:p-6 flex justify-center">
                    <h3 className="text-3xl">
                        Please select a region to see analytics
                    </h3>
                </div>
            </div>
        </div>
            <div className="px-4 md:px-10 mx-auto w-full -m-24">
            {region && (
                <div className="flex flex-col min-h-screen justify-between">
                <div className="mb-auto">
                    <Dashboard region={region} brand={brand}/>
                </div>
                <div className="w-full">
                    <FooterAdmin />
                </div>
            </div>
            )}
        </div>
    </>
  );
}
