import React from "react";

// model for regions, just to aid development
const mockRegions = {
    region_id: "1",
    region_name: "Nairobi",
}



export default function RegionSelect({regions, selectRegion}){

    return(
        <div className="flex items-center w-full">
            <div className="w-full">
                <label className="block font-bold px-12  mb-1"
                       htmlFor="seller-name">
                    Select a region
                </label>
            </div>
            <div className="w-full">
                <select
                    className="block w-full rounded"
                    id="seller-name"
                    defaultValue="Select a Region"
                    onChange={(e) => selectRegion(e.target.value)}
                >
                    <option disabled >Select a Region</option>
                    {regions.map((region,idx) => (
                        <option key={idx} value={region.region_id}>{region.region_name}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}