import React from "react";

// components

import CardHistoricalStockoutLineChart from "components/Cards/CardHistoricalStockoutLineChart.js";
import CardSalesLineChart from "components/Cards/CardSalesLineChart.js";
import CardOutletsLineChart from "components/Cards/CardOutletsLineChart.js";
import CardMonthlyLostSalesLineChart from "components/Cards/CardMonthlyLostSalesLineChart.js";
import CardDailyLostSalesLineChart from "../../components/Cards/CardDailyLostSalesLineChart";
import CardCurrentStockoutPieChart from "components/Cards/CardCurrentStockoutPieChart.js";
import CardProductsTable from "../../components/Cards/CardProductsTable";

export default function Dashboard({region, brand}) {

  return (
    <>
        <section className="relative bg-white rounded-lg shadow-md">
            <div className="p-4 pb-6">
                <h2 className="text-3xl font-semibold text-black">
                    Stock Data
                </h2>
            </div>
          <div className="flex flex-wrap">
            <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
              <CardHistoricalStockoutLineChart region={region} brand={brand} />
            </div>
            <div className="w-full xl:w-4/12 px-4">
              <CardCurrentStockoutPieChart region={region} brand={brand} />
            </div>
          </div>
        </section>
        <section className="mt-4 relative bg-white rounded-lg shadow-md">
            <div className="p-4 pb-6">
                <h2 className="text-3xl font-semibold text-black">
                    Product Data
                </h2>
            </div>
          <div className="flex flex-wraps">
            <div className="w-full mb-12 xl:mb-0 px-4">
              <CardProductsTable region={region} brand={brand} />
            </div>
          </div>
        </section>
        <section className="mt-4 relative bg-white rounded-lg shadow-md">
            <div className="p-4 pb-6">
                <h2 className="text-3xl font-semibold text-black">
                    Performance Data
                </h2>
            </div>
          <div className="flex flex-wrap">
            <div className="w-full w-6/12 mb-12 xl:mb-0 px-2">
              <CardSalesLineChart region={region} brand={brand} />
            </div>
            <div className="w-full w-6/12 mb-12 xl:mb-0 px-2">
              <CardOutletsLineChart region={region} brand={brand} />
            </div>
          </div>
            <div className="flex flex-wrap">
            <div className="w-full w-6/12 mb-12 xl:mb-0 px-2">
              <CardMonthlyLostSalesLineChart region={region} brand={brand} />
            </div>
            <div className="w-full w-6/12 mb-12 xl:mb-0 px-2">
              <CardDailyLostSalesLineChart region={region} brand={brand} />
            </div>
          </div>
        </section>
    </>
  );
}
