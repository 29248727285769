import React from "react";
import {useState, useEffect} from "react";
import {FadeLoader} from "react-spinners";
import app from "../../services/firebase.config"
import axios from "axios";

// pagination stuff
const calculateRange = (data, rowsPerPage) => {
  const range = [];
  const num = Math.ceil(data.length / rowsPerPage);
  let i = 1;
  for (let i = 1; i <= num; i++) {
    range.push(i);
  }
  return range;
};

const sliceData = (data, page, rowsPerPage) => {
  return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

const useTable = (data, page, rowsPerPage) => {
  const [tableRange, setTableRange] = useState([]);
  const [slice, setSlice] = useState([]);

  useEffect(() => {
    const range = calculateRange(data, rowsPerPage);
    setTableRange([...range]);

    const slice = sliceData(data, page, rowsPerPage);
    setSlice([...slice]);
  }, [data, setTableRange, page, setSlice]);

  return { slice, range: tableRange };
};

const TableFooter = ({ range, setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  return (
      <div className="py-2">
        <nav className="block">
          <ul className="flex justify-center mt-3 pl-0 rounded list-none flex-wrap">
            <li>
              <button className={`first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded items-center justify-center leading-tight relative border border-solid bg-white
                ${page === 1 ? "text-blueGray-300" : "border-tajir text-tajir"}`}
                onClick={() => page === 1 ? null : setPage(page - 1)} disabled={page === 1}
              >
                <i className="fas fa-chevron-left -ml-px"></i>
              </button>
            </li>
            {range.map((el, index) => (
                <li className="ml-1" key={index}>
                  <button
                    className={`first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded items-center justify-center leading-tight relative border border-solid ${
                      page === el ? 'text-white bg-tajir' : 'bg-white text-tajir'
                    }`}
                    onClick={() => setPage(el)}
                  >
                    {el}
                  </button>
                </li>
              ))}
            <li className="ml-1">
              <button className={`first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded items-center justify-center leading-tight relative border bg-white
                    ${(page === range.length || range.length === 0) ? "text-blueGray-300" : "border-tajir text-tajir"}`}
                onClick={() => (page === range.length || range.length === 0) ? null : setPage(page+1)} disabled={(page === range.length || range.length === 0)}
              >
                <i className="fas fa-chevron-right -mr-px"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
  );
};

// sku status colors
const statusColors = {
  "In Stock": "text-emerald-500",
  "Out of Stock": "text-red-500",
  "Stuck": "text-yellow-500",
  "Low Stock": "text-orange-500",
}

// mock skus data, just to aid in development
const mockSkus = {
  sku_id: "1",
  sku_name: "SKU 1",
  sku_image_url: "an image url",
  sku_price: 100,
  sku_stock_status: "In Stock",
}

export default function CardProductsTable({color = "light", region, brand, rowsPerPage = 10}) {
  const [skus, setSkus] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const { slice, range } = useTable(skus, page, rowsPerPage);

  useEffect(() => {
    if(!region) return;
    const api = `${process.env.REACT_APP_API_URL}/skus`;
    const config ={
      headers: {
        "firebase-authorization-token": window.localStorage.getItem("firebase-authorization-token"),
      },
      params: {
        vendor_email: app.auth().currentUser.email,
        region_id: region,
        brand_id: brand,
      }
    }
    setLoading(true);
    axios.get(api, config)
        .then((response) => {
            setSkus(response.data.data);
            setLoading(false);
            setPage(1);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
            setPage(1);
        })
    }, [region, brand]);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 ">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Products
              </h3>
            </div>
            <FadeLoader
                  color="#4caf50"
                  loading={loading}
                  height={10}
                  margin={-5}
                  width={3}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
          </div>
        </div>
        {skus !== [] && (
            <>
              <div className="block w-full overflow-x-auto px-6 h-650-px">
                {/* Products table */}
                <table className="items-center w-full bg-transparent border-collapse border mb-2 h-600-px">
                      <thead className="">
                        <tr>
                          <th
                            className={
                              "px-6 align-middle border border-solid py-3 text-xs uppercase w-2/12 whitespace-nowrap font-semibold text-left " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-200"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                            }
                          >
                            Image
                          </th>
                          <th
                            className={
                              "px-6 align-middle border border-solid py-3 text-xs uppercase whitespace-nowrap font-semibold w-5/12 text-left " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-200"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                            }
                          >
                            Sku
                          </th>
                          <th
                            className={
                              "px-6 align-middle border border-solid py-3 text-xs uppercase whitespace-nowrap font-semibold w-2/12 text-left " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-200"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                            }
                          >
                            Price
                          </th>
                          <th
                            className={
                              "px-6 align-middle border border-solid py-3 text-xs uppercase whitespace-nowrap w-3/12 font-semibold text-left " +
                              (color === "light"
                                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-200"
                                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                            }
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                          {slice.map((el, idx) => (
                            <tr className="border h-15-px" key={idx}>
                              <th className= "align-middle text-xs whitespace-nowrap text-left flex items-center justify-center">
                                <img
                                  src={el.sku_image_url}
                                  className=" h-15 bg-white"
                                  alt="..."
                                ></img>{" "}

                              </th>
                              <td className=" px-6 align-middle text-l whitespace-nowrap p-4 border">
                                <span
                                  className={
                                    "ml-3" +
                                    +(color === "light" ? "text-blueGray-600" : "text-white")
                                  }
                                >
                                  {el.sku_name}
                                </span>
                              </td>
                              <td className=" px-6 align-middle text-l whitespace-nowrap p-4 border">
                                {el.sku_price}
                              </td>
                              <td className=" px-6 align-middle  text-l whitespace-nowrap p-4 border">
                                <i className={`fas fa-circle mr-2 ${
                                  statusColors[el.sku_stock_status]
                                }`}></i> {el.sku_stock_status}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <th></th>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                      </tbody>
                    </table>
              </div>
              <div className="mb-6">
                {range.length === 1 ? null : <TableFooter range={range} slice={slice} setPage={setPage} page={page} />}
              </div>
            </>
        )}
      </div>
    </>
  );
}
