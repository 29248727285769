import React from "react";
import {useContext} from "react";
import {FadeLoader} from "react-spinners";
import {AuthContext} from "../../services/Auth";
import {Redirect} from "react-router";

export default function TajirSplash() {
    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Redirect to="/analytics" />;
    }
    return (
        <main>
          <section className="relative w-full h-full min-h-screen flex justify-center items-center">
            <div className="container my-auto mx-auto h-full ">
            <div className="flex content-center items-center justify-center h-full flex-col">
              <div className="w-full ">
                <img src={require("assets/img/tajir-logo.png").default}
                      alt="..." className="align-middle mx-auto my-auto"
                />
              </div>
                <div className="w-full flex">
                    <div className="align-middle mx-auto my-auto">
                        <FadeLoader color="#4caf50" loading={true} />
                    </div>

              </div>
            </div>
            </div>
          </section>
        </main>
    )
}