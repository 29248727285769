import React from "react";

// model for brands, just to aid development
const mockBrands = {
    brand_id: "1",
    brand_name: "Nairobi",
}


export default function BrandSelect({brands, selectBrand}){
    return(
        <div className="flex items-center w-full">
            <div className="w-full">
                <label className="block font-bold text-right px-12  mb-1"
                       htmlFor="brand-name">
                    Filter by brand
                </label>
            </div>
            <div className="w-full">
                <select
                    className="block w-full rounded"
                    id="brand-name"
                    defaultValue="All Brands"
                    onChange={(e) => selectBrand(e.target.value)}
                >
                    <option value={""}>All Brands</option>
                    {brands.map((brand,idx) => (
                        <option key={idx} value={brand.brand_id}>{brand.brand_name}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}