import React from "react";
import {useState, useEffect} from "react";
import axios from "axios";
import {FadeLoader} from "react-spinners";
import app from "../../services/firebase.config"

// components
import RegionSelect from "./regionSelect";
import BrandSelect from "./brandSelect";

export default function Header({region, selectRegion, selectBrand}) {
    const [regions, setRegions] = useState([]);
    const [brands, setBrands] = useState([]);
    const [regionLoading, setRegionLoading] = useState(false);
    const [brandLoading, setBrandLoading] = useState(false);

    useEffect(() => {
        const region_api = `${process.env.REACT_APP_API_URL}/regions`;
        const config ={
          headers: {
            "firebase-authorization-token": window.localStorage.getItem("firebase-authorization-token"),
          },
          params: {
            vendor_email: app.auth().currentUser.email,
          }
        }
        setRegionLoading(true);
        axios.get(region_api, config)
            .then((response) => {
                setRegions(response.data.data);
                setRegionLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setRegionLoading(false);
            });
    },[]);

    useEffect(() => {
        if(!region) return;

        const brand_api = `${process.env.REACT_APP_API_URL}/brands`;
        setBrandLoading(true);
        const config ={
          headers: {
            "firebase-authorization-token": window.localStorage.getItem("firebase-authorization-token"),
          },
          params: {
            vendor_email: app.auth().currentUser.email,
              region_id: region,
          }
        }
        axios.get(brand_api, config)
            .then((response) => {
                setBrands(response.data.data);
                setBrandLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setBrandLoading(false);
            });
    }, [region]);

  return (
    <>
      {/* Header */}
      <div className="relative bg-tajir md:pt-10 pb-32 pt-12">
        <div className="mx-auto lg:w-full">
          <div className="w-full md:px-10 rounded ">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white">
                  <div className="flex flex-wrap justify-between items-center px-4 pt-4">
                      <div>
                          <h2 className="font-semibold text-4xl text-black lg:px-10">
                            Analytics Dashboard
                          </h2>
                      </div>
                        <div className="w-3/12">
                            <img src={require("assets/img/tajir-logo.png").default}
                                  alt="..." className="object-fit rounded align-middle border-none"
                            />
                        </div>
                      <hr className="w-full border-tajir" />
                </div>
                  <div className="rounded-t mb-0 px-4 py-3 pb-6 bg-transparent">
                    <form className="flex w-full justify-center ">
                      <div className="flex flex-col items-center justify-center w-10/12 ">
                            <div className="flex flex-row w-full justify-center items-center text-center content-center">
                                <FadeLoader
                                color="#4caf50"
                                loading={regionLoading}
                                height={15}
                                margin={-1}
                                width={5}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div>
                            <div className="flex flex-row w-full mt-3">
                                <div className="w-full">
                                    {regionLoading ? null : <RegionSelect regions={regions} selectRegion={selectRegion} />}
                                </div>
                                <div className="w-full">
                                    {!region || brandLoading ?
                                        <div className="flex justify-center items-center">
                                            <FadeLoader
                                                color="#4caf50"
                                                loading={brandLoading}
                                                height={12}
                                                margin={-3}
                                                width={3}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </div>
                                        :
                                        <BrandSelect brands={brands} selectBrand={selectBrand} />
                                    }
                                </div>
                            </div>

                      </div>
                    </form>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}
