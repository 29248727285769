import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./services/Auth";
import PrivateRoute from "./services/PrivateRoute";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts
import Admin from "layouts/Admin.js";
import Login from "./views/auth/Login";
import TajirSplash from "./views/auth/TajirSplash";

// Environment variables
require('dotenv').config();

ReactDOM.render(
    <AuthProvider>
      <BrowserRouter>
        <Switch>
            {/* add routes with layouts */}
            <PrivateRoute exact path="/analytics" component={Admin} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/verify" component={TajirSplash} />
            <Route path="*" component={Login} />
        </Switch>
      </BrowserRouter>
    </AuthProvider>,
  document.getElementById("root")
);
