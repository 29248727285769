import React, { useEffect, useState } from "react";
import app from "./firebase.config.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    let email = window.localStorage.getItem('emailForSignIn');

    if (app.auth().isSignInWithEmailLink(window.location.href)) {
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      app
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          window.localStorage.removeItem('emailForSignIn');
        })
        .catch(function(error) {
          alert(error);
        });
    }

    app.auth().onAuthStateChanged(setCurrentUser);
    app.auth().onAuthStateChanged((user) => {
      let sessionTimeout = null;
      if (user === null) {
        // User is logged out.
        // Clear the session timeout.
        sessionTimeout && clearTimeout(sessionTimeout);
        sessionTimeout = null;
      } else {
        // User is logged in.
        // Fetch the decoded ID token and create a session timeout which signs the user out.

        user.getIdTokenResult().then((idTokenResult) => {
          //store the id token in local storage
          window.localStorage.setItem('firebase-authorization-token', idTokenResult.token);
          // Make sure all the times are in milliseconds!
          const authTime = idTokenResult.claims.auth_time * 1000;
          const sessionDuration = 1000 * 60 * 60 * 24; //  24 hours
          const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
          sessionTimeout = setTimeout(() => app.auth().signOut(), millisecondsUntilExpiration);
        });
      }
    })
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
