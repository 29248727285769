import React, {useCallback, useContext, useState, useEffect} from "react";
import {withRouter, Redirect} from "react-router";
import {FadeLoader} from "react-spinners";
import app from "../../services/firebase.config";
import {AuthContext} from "../../services/Auth";
import axios from "axios";


const Login = ({history}) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isInputDisabled, setIsInputDisabled] = useState(false);

    const handleLogin = useCallback(
    async event => {
        // Added
        var actionCodeSettings = {
          url: window.location.href,
          handleCodeInApp: true
        };

        event.preventDefault();
        const { email } = event.target.elements;
        const api = `${process.env.REACT_APP_API_URL}/verify`;
        setError(null);
        try {
            setLoading(true);
            const response = await axios.post(api, {vendor_email: email.value});
            if(response.data.status === "success"){
                await app.auth().sendSignInLinkToEmail(email.value, actionCodeSettings);
                setLoading(false);
                window.localStorage.setItem("emailForSignIn", email.value);
                history.push("/login");
                // disable input field for 30 seconds
                setIsInputDisabled(true);
                setTimeout(() => setIsInputDisabled(false), 30000);
            }
        }
        catch (error) {
            setError(error);
            setLoading(false);
        }
    }, [history]
    );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/analytics" />;
  }

  return (
    <main>
      <section className=" w-full h-full min-h-screen flex justify-center items-center">
        <div className="container my-auto mx-auto h-full ">
        <div className="flex content-center items-center justify-center ">
          <div className="w-full lg:w-8/12 ">
            <video className="" autoPlay muted loop
                     src="https://www.tajir.app/lib_vQIEVQuMflXIplYx/evuf79qf9ncn7kkc.mp4">
            </video>
          </div>
          <div className="w-full lg:w-4/12 px-2">
            <div className=" flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0 h-500-px">
              <div className="rounded-t mb-0 px-6 py-6 flex flex-wrap justify-center items-center">
                <div className="items-center justify-center">
                  <img src={require("assets/img/tajir-logo.png").default}
                          alt="..." className="h-150-px align-middle border-none"
                    />
                </div>
                <hr className="w-10/12 border-tajir" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <h6 className="text-blueGray-500 text-md font-bold">
                    Tajir Analytics
                  </h6>
                </div>
                <form onSubmit={handleLogin}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      disabled={isInputDisabled}
                    />
                  </div>
                  <div className="text-center mt-6">
                    {window.localStorage.getItem("emailForSignIn") ? (
                      <h6 className="text-blueGray-600 text-sm font-semibold">
                        A <span className="text-tajir">login link</span> has been sent to your email address.
                        Check your <b>spam</b> folder or&nbsp;
                            <button
                                type="submit"
                                className={isInputDisabled ? "text-blueGray-400" : "text-tajir underline"}
                                disabled={isInputDisabled}>
                                resend
                            </button>
                          &nbsp;the link in 30 seconds.
                          <br />
                      </h6>
                    ) : (
                        <button
                          className="bg-tajir text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Sign In
                        </button>

                    )}
                  </div>
                    <div className="flex items-center justify-center mt-6">
                        <div className="text-center ml-3">
                            <FadeLoader
                                color={"#4caf50"}
                                loading={loading}
                                height={12}
                                width={2}
                                margin={-5}
                            />
                        </div>
                        {error && <h6 className="text-red-500 text-sm font-semibold">
                            Your email address could not be verified.
                        </h6>}
                    </div>
                </form>
              </div>
            </div>

            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default withRouter(Login);
