import React from "react";
import {useEffect, useState} from "react";
import Chart from "chart.js";
import {FadeLoader} from "react-spinners";
import app from "../../services/firebase.config"
import axios from "axios";
import numbro from "numbro";

// model for historical stockout, just to aid development
const mockHistoricalStockout = {
    hour: new Date(), // a date
    stockout_percentage: 10, // percentage of skus out of stock
}

export default function CardHistoricalStockoutLineChart({region, brand}) {
  const [historicalStockout, setHistoricalStockout] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!region) return;

    const api = `${process.env.REACT_APP_API_URL}/historical_stockout`;
    const config ={
      headers: {
        "firebase-authorization-token": window.localStorage.getItem("firebase-authorization-token"),
      },
      params: {
        vendor_email: app.auth().currentUser.email,
        region_id: region,
        brand_id: brand,
      }
    }
    setLoading(true);
    axios.get(api, config)
        .then((response) => {
            setHistoricalStockout(response.data.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        })
    }, [region, brand]);

  useEffect(() => {
    if(!historicalStockout) return;

    const config = {
      type: "line",
      data: {
        labels: historicalStockout.map(data => new Date(data.hour)), // array of dates, to be formatted in chart component
        datasets: [
          {
            label: "Stockout",
            backgroundColor: "#FFEAE9",
            borderColor: "red",
            data: historicalStockout.map(data => (data.stockout_percentage * 100).toFixed(2)),
            fill: false,
            tension: 0.1,
            pointRadius: 0,
            borderWidth: 2,
          }

        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label + ": " + numbro(tooltipItem.yLabel/100).format({output: "percent", mantissa: 2});
            }
          }
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              type: "time",
              ticks: {
                fontColor: "rgba(123,132,123,.7)",
                autoSkip: true,
                maxTicksLimit: 10,
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Hour",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(123,132,123,.7)",
                beginAtZero: true,
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Stockout %",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(123, 123, 132, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    var ctx = document.getElementById("line-chart-stockout").getContext("2d");
    window.myHistoricalStockoutLineChart?.destroy();
    window.myHistoricalStockoutLineChart = new Chart(ctx, config);
  }, [historicalStockout]);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h2 className=" text-xl font-semibold">
                Historical Stockout
              </h2>
            </div>
            <FadeLoader
                  color="#4caf50"
                  loading={loading}
                  height={10}
                  margin={-5}
                  width={3}
                  aria-label="Loading Spinner"
                  data-testid="loader"
            />
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          {historicalStockout && (
              <div className="relative h-350-px">
                <canvas id="line-chart-stockout"></canvas>
              </div>
          )}

        </div>
      </div>
    </>
  );
}
